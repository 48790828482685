import * as privmx from "privfs-client";
import { Client } from "./Client";
import * as api from "./api";
import * as service from "./service";
import * as utils from "./utils";

const PrivMxSimpleClient = {
    privmx,
    api,
    service,
    utils,
    Client,
};

declare global {
    interface Window {
        PrivMxSimpleClient: typeof PrivMxSimpleClient;
    }
}

window.Buffer = privmx.Buffer.Buffer;

export = PrivMxSimpleClient;
