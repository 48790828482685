import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';

export class RequestApi implements PmxApi.api.request.IRequestApi {
    constructor(private gateway: privmx.gateway.RpcGateway) {}

    private async request<T>(method: string, params: unknown): Promise<T> {
        return await this.gateway.request(method, params);
    }

    createRequest(
        model: PmxApi.api.request.CreateRequestModel
    ): Promise<PmxApi.api.request.CreateRequestResult> {
        return this.request('createRequest', model);
    }

    destroyRequest(model: PmxApi.api.request.DestroyRequestModel): Promise<PmxApi.api.core.OK> {
        return this.request('destroyRequest', model);
    }

    sendChunk(model: PmxApi.api.request.ChunkModel): Promise<PmxApi.api.core.OK> {
        return this.request('sendChunk', model);
    }

    commitFile(model: PmxApi.api.request.CommitFileModel): Promise<PmxApi.api.core.OK> {
        return this.request('commitFile', model);
    }
    
    getRequestConfig(): Promise<PmxApi.api.request.RequestConfig> {
        return this.request('getRequestConfig', {});
    }
}
