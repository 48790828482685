import * as privmx from "privfs-client";
import { InquiryService } from "./service";
import { CaptchaApi } from "./api/CaptchaApi";

export interface ClientOptions {
    host?: string;
    apiUrl?: string;
    websocket?: boolean;
}

export class Client {
    
    private gateway: privmx.gateway.RpcGateway | null = null;
    private inquiryService: InquiryService | null = null;
    private captchaApi: CaptchaApi | null = null;
    
    constructor(private options: ClientOptions) {
    }
    
    async init() {
        if (this.gateway) {
            return;
        }
        const host = this.options.host || document.location.hostname;
        const priv = privmx.crypto.serviceSync.eccPrivRandom();
        if (this.options.apiUrl) {
            privmx.core.PrivFsRpcManager.urlMap[
                `${host}:v2.0`
            ] = this.options.apiUrl
        }
        this.gateway = await privmx.core.PrivFsRpcManager.getEcdheGateway({
            host: host,
            key: priv,
            rpcConfigEnhancer: (cfg) => {
                cfg.websocket = this.options.websocket === true;
                return cfg;
            }
        });
    }
    
    getInquiryService() {
        if (!this.gateway) {
            throw new Error("Client has not been initialized")
        }
        if (!this.inquiryService) {
            this.inquiryService = new InquiryService(this.gateway);
        }
        return this.inquiryService;
    }
    async createCaptcha() {
        if (!this.gateway) {
            throw new Error("Client has not been initialized");
        }
        this.captchaApi = new CaptchaApi(this.gateway);
        return this.captchaApi.createCaptcha();
    }
}
