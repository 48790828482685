import * as PmxApi from "privmx-server-api";

export class Base64 {
    
    static EMPTY = <PmxApi.api.core.Base64>"";
    
    static from(buffer: Buffer): PmxApi.api.core.Base64 {
        return <PmxApi.api.core.Base64>buffer.toString("base64");
    }
    
    static toBuf(base64: PmxApi.api.core.Base64): Buffer {
        return Buffer.from(base64, "base64");
    }
}