import { Deferred } from "./Deferred";

export type ThumbnailSizeMode = "contain" | "fill";

export interface ThumbnailOptions {
    boxWidth: number;
    boxHeight: number;
    sizeMode: ThumbnailSizeMode;
    allowUpscaling: boolean;
}

export class ThumbnailGenerator {
    static canGenerate(file: File): boolean {
        return file.type.startsWith("image/");
    }

    static async generate(file: File, thumbnailOptions?: ThumbnailOptions): Promise<File> {
        const options: ThumbnailOptions = {
            ...(thumbnailOptions ?? {}),
            boxWidth: 200,
            boxHeight: 200,
            sizeMode: "contain",
            allowUpscaling: true
        };
        const resultDeferred = new Deferred<File>();
        const img = document.createElement("img");
        img.onload = () => {
            const { w, h } = this.calculateTargetSize(options, img.naturalWidth, img.naturalHeight);
            const canvas = document.createElement("canvas")!;
            const ctx = canvas.getContext("2d")!;
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(img, 0, 0, w, h);
            canvas.toBlob((blob) => {
                if (!blob) {
                    resultDeferred.reject("Cannot render canvas to blob");
                    return;
                }
                const thumbFile = new File([blob], file.name, { type: blob.type });
                resultDeferred.resolve(thumbFile);
            }, file.type);
        };
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
            img.src = readerEvent.target!.result as string;
        };
        reader.readAsDataURL(file);
        return resultDeferred.promise;
    }

    private static calculateTargetSize(
        options: ThumbnailOptions,
        origWidth: number,
        origHeight: number
    ): { w: number; h: number } {
        let scale: number = 1.0;
        if (options.sizeMode === "contain") {
            scale = Math.min(options.boxWidth / origWidth, options.boxHeight / origHeight);
        } else {
            scale = Math.max(options.boxWidth / origWidth, options.boxHeight / origHeight);
        }
        if (!options.allowUpscaling) {
            scale = Math.min(1.0, scale);
        }
        const w: number = origWidth * scale;
        const h: number = origHeight * scale;
        return { w, h };
    }
}
