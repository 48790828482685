export type FormEntries = FormEntry[];

export interface BaseFormEntry {
    name: string;
}

export interface ShortTextFormEntry extends BaseFormEntry {
    type: "shortText";
    value: string;
}

export interface LongTextFormEntry extends BaseFormEntry {
    type: "longText";
    value: string;
}

export interface SingleChoiceFormEntry extends BaseFormEntry {
    type: "singleChoice";
    value: string | null;
}

export interface MultiChoiceFormEntry extends BaseFormEntry {
    type: "multiChoice";
    value: string[];
}

export interface FileFormEntry extends BaseFormEntry {
    type: "file";
    value: File[];
}

export type FormEntry = ShortTextFormEntry | LongTextFormEntry | SingleChoiceFormEntry | MultiChoiceFormEntry | FileFormEntry;

const ENTITY_MAP: {[key: string]: string} = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    "\"": "&quot;",
    "'": "&#39;",
    "`": "&#x60;",
    "=": "&#x3D;"
};

export class FormEntriesExtractor {
    
    constructor(private form: HTMLFormElement) {
    }
    
    private escapeHtml(str: string) {
        if (str == null) {
            return "";
        }
        const ss = typeof(str) == "string" ? str : ("" + str);
        return ss.replace(/[&<>"'`=]/g, s => {
            return ENTITY_MAP[s] as string;
        });
    }
    
    private escapeAttributeValue(str: unknown): string {
        if (str == null) {
            return "";
        }
        const ss = typeof(str) == "string" ? str : ("" + str);
        return ss.replace(/"/g, "&quot;");
    }
    
    extractFormEntries(): FormEntries {
        return [
            ...this.getShortTextEntries(),
            ...this.getLongTextEntries(),
            ...this.getSingleChoiceEntries(),
            ...this.getMultiChoiceEntries(),
            ...this.getFiles(),
        ];
    }
    
    private getShortTextEntries() {
        const textInputs = [...this.form.querySelectorAll("input[type=text][name]")] as HTMLInputElement[];
        const res: ShortTextFormEntry[] = textInputs.map(x => ({
            type: "shortText",
            name: this.escapeAttributeValue(x.name),
            value: this.escapeHtml(x.value),
        }));
        return res;
    }
    
    private getLongTextEntries() {
        const textareaInputs = [...this.form.querySelectorAll("textarea[name]")] as HTMLTextAreaElement[];
        const res: LongTextFormEntry[] = textareaInputs.map(x => ({
            type: "longText",
            name: this.escapeAttributeValue(x.name),
            value: this.escapeHtml(x.value),
        }));
        return res;
    }
    
    private createSingleChoiceValue(name: string, value?: string): {type: "singleChoice", name: string, value: string | undefined} {
        return {type: "singleChoice", name: name, value: value};
    }
    
    private getSingleChoiceEntries() {
        const radioInputs = [...this.form.querySelectorAll("input[type=radio][name][value]")] as HTMLInputElement[];
        const uniq: {[name: string]: {type: "singleChoice", name: string, value: string | undefined}} = {};

        for (const item of radioInputs) {
            if (!item.name) { 
                throw Error("cannot parse answers");
            }
            const res = uniq[item.name] || this.createSingleChoiceValue(item.name);
            if (item.checked) {
                res.value = this.escapeHtml(item.value);
            }
            uniq[item.name] = res;
        
            if (item.checked) {
                uniq[item.name] = this.createSingleChoiceValue(item.name, item.value);
            }
            
        }
        const radioInputsResPre = Object.values(uniq);

        const radioInputsRes: SingleChoiceFormEntry[] = radioInputsResPre.map(x => ({
            type: "singleChoice",
            name: this.escapeAttributeValue(x.name),
            value: x.value ? this.escapeHtml(x.value) : null,
        }));
        
        const singleSelectInputs = [...this.form.querySelectorAll("select[name]:not([multiple])")] as HTMLSelectElement[];
        const singleSelectInputsRes: SingleChoiceFormEntry[] = singleSelectInputs.map(x => ({
            type: "singleChoice",
            name: this.escapeAttributeValue(x.name),
            value: this.escapeHtml(x.value),
        }));
        const res: SingleChoiceFormEntry[] = [
            ...radioInputsRes,
            ...singleSelectInputsRes,
        ];
        return res;
    }
    
    private getMultiChoiceEntries() {
        const checkboxInputs = [...this.form.querySelectorAll("input[type=checkbox][name][value]")] as HTMLInputElement[];
        const uniq: {[name: string]: {type: "multiChoice", name: string, value: string[]}} = {};
        for (const item of checkboxInputs) {
            if (!item.name) { 
                throw Error("cannot parse answers");
            }
            const res = uniq[item.name] || {type: "multiChoice", name: item.name, value: []};
            if (item.checked) {
                res.value.push(item.value);
            }
            uniq[item.name] = res;
        }
        const checkboxInputsRes = Object.values(uniq);
        
        const multiSelectInputs = [...this.form.querySelectorAll("select[name][multiple]")] as HTMLSelectElement[];
        const multiSelectInputsRes: MultiChoiceFormEntry[] = multiSelectInputs.map(x => ({
            type: "multiChoice",
            name: this.escapeAttributeValue(x.name),
            value: [...x.selectedOptions].map(y => this.escapeHtml(y.value)),
        }));
        const res: MultiChoiceFormEntry[] = [
            ...checkboxInputsRes,
            ...multiSelectInputsRes,
        ];
        return res;
    }
    
    private getFiles() {
        const fileInputs = [...this.form.querySelectorAll("input[type=file]")] as HTMLInputElement[];
        const res: FileFormEntry[] = fileInputs.map(x => ({
            type: "file",
            name: this.escapeAttributeValue(x.name),
            value: x.files ? [...x.files] : [],
        }));
        return res;
    }
    
}
