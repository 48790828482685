import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';

export class CaptchaApi implements PmxApi.api.captcha.ICaptchaApi {
    constructor(private gateway: privmx.gateway.RpcGateway) {}

    private async request<T>(method: string, params: unknown): Promise<T> {
        return await this.gateway.request(method, params);
    }

    createCaptcha(): Promise<PmxApi.api.captcha.CaptchaChallenge> {
        return this.request('captcha.createCaptcha', {});
    }
}
