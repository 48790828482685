import * as privmx from "privfs-client";
import * as PmxApi from "privmx-server-api";

export class EciesEncryptor {
    static async encrypt(pub: privmx.crypto.ecc.PublicKey, data: Buffer) {
        const priv = privmx.crypto.serviceSync.eccPrivRandom();
        const cipher = await privmx.crypto.service.eciesEncrypt(priv, pub, data);
        return Buffer.concat([
            Buffer.from("e", "utf8"),
            priv.getPublicKey().toDER(),
            pub.toDER(),
            cipher
        ]);
    }

    static async decrypt(priv: privmx.crypto.ecc.PrivateKey, cipher: Buffer) {
        if (cipher[0] !== 101 || cipher.length < 67) {
            throw new Error("Invalid first byte of cipher");
        }
        const externalPub = cipher.slice(1, 34);
        const myPub = cipher.slice(34, 67);
        const eciesBuffer = cipher.slice(67);
        const pub = priv.getPublicKey().toDER();
        if (!pub.equals(myPub)) {
            throw new Error("Given priv key does not match");
        }
        const externalPubEcc = privmx.crypto.ecc.PublicKey.fromDER(externalPub);
        return privmx.crypto.service.eciesDecrypt(priv, externalPubEcc, eciesBuffer);
    }

    static async encryptToBase64(pub: privmx.crypto.ecc.PublicKey, data: Buffer) {
        const cipher = await EciesEncryptor.encrypt(pub, data);
        return cipher.toString("base64") as PmxApi.api.core.Base64;
    }

    static async decryptFromBase64(
        priv: privmx.crypto.ecc.PrivateKey,
        cipher: PmxApi.api.core.Base64
    ) {
        const buffer = Buffer.from(cipher, "base64");
        return EciesEncryptor.decrypt(priv, buffer);
    }

    static async encryptObjectToBase64<T = any>(pub: privmx.crypto.ecc.PublicKey, data: T) {
        const buffer = Buffer.from(JSON.stringify(data), "utf8");
        return EciesEncryptor.encryptToBase64(pub, buffer);
    }

    static async decryptObjectFromBase64<T = any>(
        priv: privmx.crypto.ecc.PrivateKey,
        cipher: PmxApi.api.core.Base64
    ) {
        const buffer = await EciesEncryptor.decryptFromBase64(priv, cipher);
        return JSON.parse(buffer.toString("utf8")) as T;
    }
}
