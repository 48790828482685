import * as privmx from "privfs-client";
import * as PmxApi from "privmx-server-api";
import {
    AttachmentMeta,
    AttachmentUtils,
    PreparedFile
} from "../utils/AttachmentUtils";
import { InquiryApi } from "../api/InquiryApi";
import { RequestApi } from "../api/RequestApi";
import { EciesEncryptor } from "../utils/EciesEncryptor";
import * as types from "../Types";
import { EccUtils } from "../utils/EccUtils";
import { FileFormEntry, FormEntries, FormEntry } from "../utils";

interface InquirySubmitAttachment {
    hmac: string; // base64
    name: string;
    mimetype: string;
    size: number;
    hasThumb: boolean;
}

interface SubmitData {
    answers: types.inquiry.SubmitedAnswer[];
}

export class InquiryService {
    public inquiryApi: InquiryApi;
    private requestApi: RequestApi;
    private inquiryPublicDataEncryptor = new InquiryPublicDataEncryptor();
    private submitDataEncryptor = new InquirySubmitDataEncryptor();
    private attachmentMetaEncryptor = new InquiryAttachmentMetaEncryptor();
    private attachmentUtils: AttachmentUtils<
        InquirySubmitAttachment,
        privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey
    >;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
    ) {
        this.inquiryApi = new InquiryApi(this.gateway);
        this.requestApi = new RequestApi(this.gateway);
        this.attachmentUtils = new AttachmentUtils(
            this.requestApi,
            this.attachmentMetaEncryptor,
            (preparedFile, hasThumb) =>
                this.preparedFileToAttachmentConverter(preparedFile, hasThumb),
        );
    }
    
    async submitInquiryFromFormEntries(inquiryId: PmxApi.api.inquiry.InquiryId, formEntries: FormEntries, captchaResult?: PmxApi.api.captcha.CaptchaObj) {
        const publicInquiry = await this.getInquiryPublicView(inquiryId);
        const publicationId: PmxApi.api.inquiry.InquiryPublicationId = publicInquiry.raw.publicationId;
        const pubKey: PmxApi.api.core.EccPubKey = publicInquiry.data.pub;
        const pubKeyBuff = Buffer.from(pubKey);
        const { data, files } = this.readFormEntries(formEntries, publicInquiry.data.questions);
        const required = this._validateRequired(publicInquiry.data.questions, formEntries);
        if (required.length > 0) {
            throw Error("Required not met. Call \'validateRequired\' to get detailed info about form's fields requirements");
        }
        const attInfo = await this.attachmentUtils.prepareAttachments(pubKey, pubKeyBuff, files);
        const captcha = publicInquiry.raw.captchaEnabled ? captchaResult : undefined;

        const res = await this.inquiryApi.submitInquiry({
            id: inquiryId,
            publicationId: publicationId,
            data: await this.submitDataEncryptor.encrypt(pubKey, data),
            attachments: attInfo.request,
            autoResponseEmail: undefined,
            captcha: captcha
        });
        return res === "OK";
    }
    
    private isQuestionRequired(q: types.inquiry.Question): boolean {
        return "required" in q && (q as any).required === true;
    }
    
    async validateRequired(inquiryId: PmxApi.api.inquiry.InquiryId, formEntries: FormEntries) {
        const publicInquiry = await this.getInquiryPublicView(inquiryId);
        // const { data } = this.readFormEntries(formEntries, publicInquiry.data.questions);
        return this._validateRequired(publicInquiry.data.questions, formEntries);
    }

    private isValueSet(entry: FormEntry | undefined): boolean {
        if (!entry) {
            return false;
        }
        const res = Array.isArray(entry.value) ? entry.value.length > 0 : entry.value !== null;
        return res;
    }
    
    public _validateRequired(questions: types.inquiry.Question[], formEntries: FormEntries) {
        const answersMap: {[id: string]: FormEntry} = {};
        for (const entry of formEntries) {
            answersMap[entry.name] = entry;
        };
        const res = questions.filter(x => this.isQuestionRequired(x) && !this.isValueSet(answersMap[x.id]));
        return res;
    }
    
    private getAnswerId(answer: types.inquiry.Answer | types.inquiry.Answer[]) {
        if (Array.isArray(answer) && answer[0]) {
            return answer[0].id;
        }
        if (!Array.isArray(answer)) {
            return answer.id;
        }
        throw new Error("cannot read answer id");
    }
    
    private readFormEntries(formEntries: FormEntries, questions: types.inquiry.Question[]) {
        const fileEntries = formEntries.filter(x => x.type === "file") as FileFormEntry[];
        const files = fileEntries.map(x => x.value).flat();
        const usedFormEntries = new Set<FormEntry>(fileEntries);
        const answers: types.inquiry.SubmitedAnswer[] = questions.filter(x => x.type !== "block").map(q => {
            const entry = formEntries.find(e => e.name === q.id);
            if (!entry) {
                throw new Error(`There is no FormEntry for question: "${q.title}" (name=${q.id})`)
            }
            if (
                (q.type === "short" && entry.type !== "shortText") ||
                (q.type === "long" && entry.type !== "longText") ||
                (q.type === "single" && entry.type !== "singleChoice") ||
                (q.type === "select" && entry.type !== "multiChoice")
            ) {
                throw new Error(`Invalid form input type for question "${q.title}" (name=${q.id})`);
            }
            usedFormEntries.add(entry);
            const rawValue = entry.value as string | string[] | File[];
            // const valueArr = typeof(rawValue) === "string" ? [rawValue] : rawValue;
            const valueArr = Array.isArray(rawValue) ? rawValue : [rawValue];
            const answers: types.inquiry.Answer[] = [];
            
            const type = q.type;
            const id = q.type !== "block" ? this.getAnswerId( q.answer) : '';
            
            if (type === 'file') {
                const iterableValue = (valueArr as File[]) || [];
                for (const f of iterableValue) {
                    answers.push({
                        id: id,
                        type: type,
                        input: f.name
                    });
                    
                }
            } else if (type === 'select' || type === 'single') {
                if (valueArr) {
                    const iterableValue = (valueArr as string[]) || [];
                    for (const v of iterableValue) {
                        answers.push({
                            id: id,
                            type: type,
                            input: v
                        });
                    }
                }
            } else if (type === 'block') {
                answers.push({
                    id: id,
                    type: 'block',
                    input: ''
                });
            } else {
                answers.push({
                    id: id,
                    type: type,
                    input: valueArr as any
                });
            }
            return {
                type: q.type,
                id: q.id,
                answer: answers
            }
        });        
        if (usedFormEntries.size !== formEntries.length) {
            const unusedFormEntries = formEntries.filter(x => !usedFormEntries.has(x));
            throw new Error(`Unused form entries: ${unusedFormEntries.map(x => x.name).join(", ")}`);
        }
        const submitData: SubmitData = {
            answers: answers,
        };
        return {
            data: submitData,
            files: files,
        };
    }

    async createSubmit(
        id: PmxApi.api.inquiry.InquiryId,
        data: SubmitData,
        files: File[]
    ) {
        const publicInquiry = await this.getInquiryPublicView(id);
        const publicationId: PmxApi.api.inquiry.InquiryPublicationId = publicInquiry.raw.publicationId;
        const pubKey: PmxApi.api.core.EccPubKey = publicInquiry.data.pub;
        const pubKeyBuff = Buffer.from(pubKey);
        const attInfo = await this.attachmentUtils.prepareAttachments(pubKey, pubKeyBuff, files);
        await this.inquiryApi.submitInquiry({
            id: id,
            publicationId: publicationId,
            data: await this.submitDataEncryptor.encrypt(pubKey, data),
            attachments: attInfo.request
        });
    }

    async getInquiryPublicView(id: PmxApi.api.inquiry.InquiryId) {
        const { inquiry } = await this.inquiryApi.getInquiryPublicView({ id: id });
        const data = this.inquiryPublicDataEncryptor.decrypt(inquiry.data);
        return { raw: inquiry, data: data };
    }

    private preparedFileToAttachmentConverter(
        preparedFile: PreparedFile,
        hasThumb: boolean
    ): InquirySubmitAttachment {
        const res: InquirySubmitAttachment = {
            hmac: preparedFile.hmac.toString("base64"),
            name: preparedFile.file.name,
            size: preparedFile.file.size,
            mimetype: preparedFile.file.type,
            hasThumb: hasThumb
        };
        return res;
    }

}

export class InquiryAttachmentMetaEncryptor {
    async encrypt(
        attachmentMeta: AttachmentMeta,
        pub: privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey
    ) {
        const pubKey =
            typeof pub === "string" ? privmx.crypto.ecc.PublicKey.fromBase58DER(pub) : pub;
        return (await EciesEncryptor.encryptObjectToBase64(
            pubKey as any,
            attachmentMeta
        )) as PmxApi.api.attachment.AttachmentMeta;
    }

    async decrypt(
        attachmentMeta: PmxApi.api.attachment.AttachmentMeta,
        priv: privmx.crypto.ecc.PrivateKey | PmxApi.api.core.EccWif
    ) {
        const privKey =
            typeof priv === "string" ? privmx.crypto.ecc.PrivateKey.fromWIF(priv) : priv;
        return (await EciesEncryptor.decryptObjectFromBase64(
            privKey as any,
            attachmentMeta
        )) as AttachmentMeta;
    }
}

export class InquirySubmitDataEncryptor {
    async encrypt(pub: privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey, data: SubmitData) {
        const pubKey =
            typeof pub === "string" ? privmx.crypto.ecc.PublicKey.fromBase58DER(pub) : pub;
        return (await EciesEncryptor.encryptObjectToBase64(
            pubKey as any,
            data
        )) as PmxApi.api.inquiry.InquirySubmitData;
    }

    async decrypt(
        priv: privmx.crypto.ecc.PrivateKey | PmxApi.api.core.EccWif,
        data: PmxApi.api.inquiry.InquirySubmitData
    ) {
        const privKey =
            typeof priv === "string" ? privmx.crypto.ecc.PrivateKey.fromWIF(priv) : priv;
        return (await EciesEncryptor.decryptObjectFromBase64(privKey as any, data)) as SubmitData;
    }
}

export class InquiryPublicDataEncryptor {
    encrypt(data: types.inquiry.InquiryData) {
        const publicData: types.inquiry.InquiryPublicData = {
            name: data.name,
            pub: EccUtils.getPublicKey(data.priv),
            questions: data.questions
        };
        const raw = Buffer.from(JSON.stringify(publicData), "utf8");
        return Buffer.concat([Buffer.from([1]), raw]).toString(
            "base64"
        ) as PmxApi.api.inquiry.InquiryPublicData;
    }

    decrypt(data: PmxApi.api.inquiry.InquiryPublicData) {
        const buffer = Buffer.from(data, "base64");
        const type = buffer[0];
        if (type === 1) {
            return JSON.parse(buffer.slice(1).toString("utf8")) as types.inquiry.InquiryPublicData;
        }
        throw new Error("Cannot decrypt public data, unsupported type");
    }
}
